import {
  bsc as bsc_,
  mainnet,
  // bscTestnet,
  // goerli,
  Chain,
} from 'wagmi/chains'

// Network chain ids
export enum CHAIN_ID {
  BSC = 56,
  BSC_TESTNET = 97,
  MAINNET = 1,
  GOERLI = 5
}

export const defaultChainId = CHAIN_ID.BSC

// Network labels
export const NETWORK_LABEL = {
  [CHAIN_ID.BSC]: 'BSC',
  [CHAIN_ID.BSC_TESTNET]: 'BSC Testnet',
  [CHAIN_ID.MAINNET]: 'Ethereum',
  [CHAIN_ID.GOERLI]: 'Goerli',
}

// Network RPC nodes
export const NETWORK_RPC = {
  [CHAIN_ID.BSC]: [
    'https://bsc-dataseed1.defibit.io',
    'https://bsc-dataseed1.binance.org',
    'https://bsc-dataseed1.ninicoin.io',
  ],
  [CHAIN_ID.BSC_TESTNET]: ['https://data-seed-prebsc-1-s1.binance.org:8545'],
  [CHAIN_ID.MAINNET]: [
    'https://ethereum.publicnode.com',
    'https://eth.llamarpc.com',
    'https://cloudflare-eth.com',
  ],
  [CHAIN_ID.GOERLI]: [
    'https://eth-goerli.public.blastapi.io',
  ],
}

// Network block explorers
export const EXPLORER_URLS = {
  [CHAIN_ID.MAINNET]: 'https://etherscan.io',
  [CHAIN_ID.GOERLI]: 'https://goerli.etherscan.io',
  [CHAIN_ID.BSC]: 'https://bscscan.com',
  [CHAIN_ID.BSC_TESTNET]: 'https://testnet.bscscan.com',
}

const bsc = {
  ...bsc_,
  rpcUrls: {
    ...bsc_.rpcUrls,
    public: {
      ...bsc_.rpcUrls.public,
      http: ['https://bsc-dataseed.binance.org/'],
    },
    default: {
      ...bsc_.rpcUrls.default,
      http: ['https://bsc-dataseed.binance.org/'],
    },
  },
} satisfies Chain

export const CHAINS = [
  mainnet,
  bsc,
]