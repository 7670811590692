import React from 'react'
import { Navbar } from '@nextui-org/react'
import { Flex, Drawer, Burger } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { Link } from 'react-router-dom'
import { StyledText } from 'components/GlobalStyle'
import { FormattedMessage } from 'react-intl'

const MenuV2: React.FC = () => {
  const [drawerOpened, { open: openDrawer, close: closeDrawer }] = useDisclosure(false)
  const [burgerOpened, { toggle: toggleBurger }] = useDisclosure(false)
  const burgerLabel = burgerOpened ? 'Close navigation' : 'Open navigation'

  return (
    <>
      <Drawer opened={drawerOpened} onClose={closeDrawer}
        overlayProps={{ opacity: 0.5, blur: 4 }}
        position='right'
      >
        <Flex ta='center' direction='column' gap={24}>
          <Link to='/home' onClick={() => closeDrawer()}>
            <StyledText color='var(--blackColor)' size={18}>
              <FormattedMessage id='home' defaultMessage='Home' />
            </StyledText>
          </Link>
          {/* {process.env.REACT_APP_PLAYGROUND_LINK && <a href={process.env.REACT_APP_PLAYGROUND_LINK} rel='noreferrer'>
            <StyledText color='var(--blackColor)' size={18}>
              <FormattedMessage id='label-playground' defaultMessage='Playground' />
            </StyledText>
          </a>}
          <Link to='/about-us' onClick={() => closeDrawer()}>
            <StyledText color='var(--blackColor)' size={18}>
              <FormattedMessage id='aboutUs' defaultMessage='About Us' />
            </StyledText>
          </Link>
          <Link to='/tnc' onClick={() => closeDrawer()}>
            <StyledText color='var(--blackColor)' size={18}>
              <FormattedMessage id='label-tnc' defaultMessage='Terms and Conditions' />
            </StyledText>
          </Link>
          <Link to='/privacy' onClick={() => closeDrawer()}>
            <StyledText color='var(--blackColor)' size={18}>
              <FormattedMessage id='label-privacy' defaultMessage='Privacy Policy' />
            </StyledText>
          </Link>
          <Link to='/shipping' onClick={() => closeDrawer()}>
            <StyledText color='var(--blackColor)' size={18}>
              <FormattedMessage id='label-shipping' defaultMessage='Shipping Info' />
            </StyledText>
          </Link>
          <Link to='/faq' onClick={() => closeDrawer()}>
            <StyledText color='var(--blackColor)' size={18}>
              <FormattedMessage id='label-faq' defaultMessage='FAQ' />
            </StyledText>
          </Link> */}
        </Flex>
      </Drawer>

      <Navbar maxWidth='fluid' disableShadow disableBlur height='auto' className='m-navbar' style={{ position: 'fixed', top: 0 }}>
        <Navbar.Brand style={{ padding: '1rem 0' }}>
          <Flex>
            <img src='/logo.png' alt='logo' style={{ maxHeight: '60px' }} />
          </Flex>
        </Navbar.Brand>
        <Navbar.Content>
          <Burger className='nav-burger' opened={burgerOpened} onClick={() => {openDrawer()}}
            color='var(--whiteColor)' aria-label={burgerLabel} size={30}
          />
        </Navbar.Content>
      </Navbar>
    </>
  )
}

export default MenuV2