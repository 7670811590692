import React, { HtmlHTMLAttributes, forwardRef } from 'react'
import {
  Text,
  Button
} from '@nextui-org/react'
import { templateId } from 'utils'
import { styled } from 'styled-components'
import { TextProps, Text as MText, createPolymorphicComponent, Button as MButton, ButtonProps } from '@mantine/core';

type TextTransforms =
  /* Keyword values */
  | "none"
  | "capitalize"
  | "uppercase"
  | "lowercase"
  | "full-width"
  | "full-size-kana"
  /* Global values */
  | "inherit"
  | "initial"
  | "revert"
  | "unset";

type TextWeights =
  | 'hairline'  // 100,
  | 'thin'  // 200,
  | 'light'  // 300,
  | 'normal'  // 400,
  | 'medium'  // 500,
  | 'semibold'  // 600,
  | 'bold'  // 700,
  | 'extrabold'  // 800,
  | 'black';  // 900


export const GeneralText: React.FC<{
  size: string | number
  color: string
  mt?: string
  transform?: TextTransforms
  weight: TextWeights
  cusColor?: string
  wordB?: string
  textAlign?: string
  children: React.ReactNode
}> = ({ size, color, mt, transform, weight, cusColor, wordB, textAlign, children }) => (
  <Text size={size} color={color} transform={transform} weight={weight} css={{
    marginTop: mt,
    color: cusColor,
    wordBreak: wordB,
    fontFamily: 'var(--fontFamily)',
    textAlign
  }}>
    {children}
  </Text>
)

export const ButtonStyled: React.FC<{
  width?: string
  height?: string
  borderRadius?: string
  isLoading?: boolean
  onClick?: any
  disabled?: boolean
  fSize?: string
  children: React.ReactNode
}> = ({ width, height, borderRadius, isLoading, onClick, disabled, fSize, children }) => (
  <Button
    rounded={templateId === '1' || templateId === '3'}
    css={{
      background: templateId === '1' ? '#DC70FA' : '#3772FF',
      width,
      height,
      borderRadius,
      fontSize: fSize
    }}
    iconRight={isLoading ? <i className="pi pi-spin pi-sync" style={{ fontSize: '1rem' }} /> : null}
    onPress={onClick}
    disabled={disabled}
  >
    {children}
  </Button>
)


// Page component as main wrapper
const StyledPage = styled.div`
  // min-height: calc(90vh - 0px);
  box-sizing: border-box;
  position: relative;
`
export const Page: React.FC<HtmlHTMLAttributes<HTMLDivElement>> = ({ children, ...props }) => {
  return (
    <>
      <StyledPage {...props}>
        {children}
      </StyledPage>
    </>
  )
}

// Text
const _StyledText = forwardRef<HTMLButtonElement, { children: React.ReactNode, other }>(({ children, ...other }) => (
  <MText size='16px' color='var(--whiteColor)' fw={400} ff='var(--fontFamily)' lh={1.5} {...other}>
    {children}
  </MText>
))
export const StyledText = createPolymorphicComponent<'button', TextProps>(_StyledText)

// Styled button
const _StyledWalletBtn = forwardRef<HTMLButtonElement, { children: React.ReactNode, other }>(({ children, ...other }, ref) => (
  <MButton
    miw={195} mah={66} h={40} ref={ref}
    styles={(theme) => ({
      root: {
        border: "none",
        zIndex: 5,
        backgroundImage: "url('/images/wallet-btn-bg-custom.svg')",
        backgroundColor: 'transparent',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        cursor: 'pointer',
        pointerEvents: 'all',
        // boxShadow: 'rgb(57, 253, 219) 0px 0px 31.4px 0px',
        filter: 'drop-shadow(0px 0px 15.399999618530273px #39FDDB)',
        '&:hover': {
          backgroundImage: "url('/images/wallet-btn-bg-custom.svg')",
          backgroundColor: 'transparent',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100% 100%'
        },
        '&:disabled': {
          cursor: 'not-allowed',
          pointerEvents: 'all',
          backgroundImage: "url('/images/wallet-btn-bg-custom.svg')",
          backgroundColor: 'transparent',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100% 100%',
          '>div>span>div': {
            color: "var(--greyColor)"
          }
        },
        '&[data-loading]': {
          '::before': {
            backgroundColor: 'transparent!important',
            cursor: 'not-allowed',
            pointerEvents: 'all',
          },
          '>div>span>div': {
            color: "var(--greyColor)"
          }
        }
      }
    })}
    {...other}
  >
    {children}
  </MButton>
))
export const StyledWalletBtn = createPolymorphicComponent<'button', (ButtonProps)>(_StyledWalletBtn)