import { CHAIN_ID } from './networks'

export type AddressMap = Partial<Record<CHAIN_ID, string>>

export default {
  multiCall: {
    1: '0x2a80799F88896E16f963284C7583f365CD645A1B',
    5: '0x925452ab590bdE90800a6265b4C6E16983194C25',
    56: '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B',
    97: '0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576',
  },
  purchaseCashierContr: {
    1: '0x6f8DDD15139400fecC1f49e2CB5F0B727379bD3d',
    5: '',
    56: '0x87e2060643a73DFf58673b29db0aac72DF7a7E5A',
    97: ''
  }
}
