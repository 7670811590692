import React from 'react'
import styled from '@emotion/styled'
import Page from 'components/Layout/Page'
import { Player } from '@lottiefiles/react-lottie-player'
import loadingJson from 'lotties/loading.json'

const Wrapper = styled(Page)`
  display: flex;
  justify-content: center;
  align-items: center;
`

const PageLoader: React.FC = () => {
  return (
    <Wrapper>
      <Player autoplay loop src={loadingJson} style={{ maxWidth: '200px' }} />
    </Wrapper>
  )
}

export default PageLoader