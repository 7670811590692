import React, { lazy, useLayoutEffect } from 'react'
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Navigate
} from 'react-router-dom'
import BigNumber from 'bignumber.js'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import PageLoader from 'components/Loader/PageLoader'
import { usePollBlockNumber } from 'state/block/hooks'
import { useUpdateNetwork } from 'state/network/hooks'
import SuspenseWithChunkError from 'components/SuspenseWithChunkError'
import { templateId } from 'utils'
import MenuV2 from 'components/MenuV2'
import FooterV2 from 'components/FooterV2'

const NotFound = lazy(() => import('./views/NotFound'))
const Home = lazy(() => import('./views/Home'))
const FAQ = lazy(() => import('./views/FAQ'))
const AboutUs = lazy(() => import('./views/AboutUs'))
const TncPolicyShip = lazy(() => import('./views/TncPolicyShip'))

const Test = lazy(() => import('./views/Test'))

// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

export const RemoveTrailingSlash = ({ ...rest }) => {
  const location = useLocation()

  // If the last character of the url is '/'
  if (location.pathname.match('/.*/$')) {
    return <Navigate replace {...rest} to={{
      pathname: location.pathname.replace(/\/+$/, ""),
      search: location.search
    }} />
  }

  return null
}

const App: React.FC = () => {
  usePollBlockNumber()
  useUpdateNetwork()

  gsap.registerPlugin(ScrollTrigger)

  // dynamic import css based on template
  useLayoutEffect(() => {
    async function importLibrary() {
      await import(`./css/Temp${templateId}.css`)
    }
    importLibrary()
  }, [])

  return (
    <BrowserRouter>
      <div className='wrapper'>
        {/* <video autoPlay loop muted playsInline style={{ width: '100%', height: '100%', objectFit: 'cover', position: 'absolute' }}>
          <source src='/images/backdrop.mp4' type='video/mp4' />
        </video> */}
        <img src='/images/background2.png' alt='bg2' style={{ width: '100%', height: '100%', objectFit: 'cover', position: 'absolute' }} />
        <div className="master-container">
          <MenuV2 />
          <SuspenseWithChunkError fallback={<PageLoader />}>
            <RemoveTrailingSlash />
            <Routes>
              <Route index element={<Navigate to="/home" />} />
              <Route path="/home">
                <Route index element={<Home />} />
              </Route>
              {/* <Route path='/faq'>
                <Route index element={<FAQ />} />
              </Route>
              <Route path='/about-us'>
                <Route index element={<AboutUs />} />
              </Route>
              <Route path='/tnc'>
                <Route index element={<TncPolicyShip cmsType='tnc' />} />
              </Route>
              <Route path='/privacy'>
                <Route index element={<TncPolicyShip cmsType='privacy' />} />
              </Route>
              <Route path='/shipping'>
                <Route index element={<TncPolicyShip cmsType='shipping' />} />
              </Route> */}

              {/* <Route path='/test'>
                <Route index element={<Test />} />
              </Route> */}

              {/* 404 */}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </SuspenseWithChunkError>
        </div>
        <FooterV2 />
      </div>
    </BrowserRouter>
  )
}

export default React.memo(App)
