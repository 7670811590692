import { Flex } from '@mantine/core'
import { StyledText } from 'components/GlobalStyle'
import React from 'react'
import { FormattedMessage } from 'react-intl'

const FooterV2: React.FC = () => {
  return (
    <Flex className='footer-flex' align='center' maw='90%' m='auto' mih='10vh' pos='relative'>
      <StyledText className='mobile-center-text' ff='var(--fontFamily2)' size={12} tt='uppercase' lts={0.96}>
        <FormattedMessage id='label-copyright' defaultMessage='CopyRight' />
        &nbsp;&copy;
        <FormattedMessage id='label-rights' defaultMessage='All Rights Reserved by ROLLBOXX.' />
      </StyledText>
    </Flex>
  )
}

export default FooterV2